import React from "react";
import { 
  StyledCardList,
  StyledCardListItem,
  StyledCaseStudyList,
  StyledCaseStudyListItem,
  StyledPromptPlaygroundListItem,
  StyledInteractionDesignListItem,
} from "../../styles/List";
import { Links } from '../../styles/Links';
import { TagList, TagItem } from "../../styles/Tags";
import { variables } from "../../styles/GlobalStyles";

const CardList = (props) => {
  return (
    <StyledCardList section={props.section} className={props.className} columns={props.columns} mdColumns={props.mdColumns} smColumns={props.smColumns}>
      {props.data.map((item) => {
        return (
          <StyledCardListItem key={item.name} section={props.section}>
            <img className="image" src={item.src} alt={item.name}/>
            <div className="container">
              <h3 className="heading">{item.name}</h3>
              <p className="description">{item.description}</p>
              <Links className="links">
                {item.links.map((link) => {
                  return (
                    <li key={link.text}><a href={link.href} target={link.target} rel="noopener noreferrer" aria-label={link.ariaLabel}>{`${link.text}`} <span className='arrow'>→</span></a></li>
                  )  
                })}
              </Links>
            </div>
          </StyledCardListItem>
        )
      })}
    </StyledCardList>
  )
}

const CaseStudyList = (props) => {
  return (
    <StyledCaseStudyList section={props.section} className={props.className}>
      {props.data.map((item) => {
        return (
          <StyledCaseStudyListItem key={item.name} section={props.section}>
            <a href={item.href} target={item.target} rel="noopener noreferrer" aria-label={item.ariaLabel} class={item.class} >
              <div className="img-container">
                <picture>
                    <source media={`(min-width: ${variables.lg})`} srcset={item.srclg} />
                    <source media={`(max-width: ${variables.md})`} srcset={item.src} />
                    <img className="image" src={item.src} alt={item.name}/>
                </picture>
              </div>
              <div className="container">
                <h3 className="heading">{item.name} <span className='arrow'>→</span></h3>
                <p className="description">{item.description}</p>
                <TagList className="tags" itembackgroundcolor={`${variables.grey100}`}>
                  {item.tags.map((tag) => {
                    return (
                      <TagItem>{tag}</TagItem>
                      )
                    })}
                </TagList>
              </div>
            </a>
          </StyledCaseStudyListItem>
        )
      })}
    </StyledCaseStudyList>
  )
}

const PromptPlaygroundList = (props) => {
  const list = props.shortList === true ? props.data.slice(0, 2) : props.data.slice();
  return (
    <StyledCardList section={props.section} className={props.className} columns={props.columns} mdColumns={props.mdColumns} smColumns={props.smColumns}>
      {list.map((item) => {
        return (
          <StyledPromptPlaygroundListItem key={item.ppNum} section={props.section}>
            <div className="prompt">
              <h3 className="heading">Prompt {item.ppNum}</h3>
              <img className="image" src={item.src} alt={item.prompt2}/>
              <p className="prompt"><span className="responsive-block"><span className="prompt1">{item.prompt1}</span> <span className="prompt2">{item.prompt2}</span></span> <span className="responsive-block"><span className="prompt3">{item.prompt3}</span> <span className="prompt4">{item.prompt4}</span></span></p>
              <a href={item.href} target="_blank" rel="noopener noreferrer" aria-label={item.ariaLabel} >View on Dribbble <span className='arrow'>→</span></a>
            </div>
          </StyledPromptPlaygroundListItem>
        )
      })}
    </StyledCardList>
  )
}

const InteractionDesignList = (props) => {
  return (
    <StyledCardList section={props.section} className={props.className} columns={props.columns} mdColumns={props.mdColumns} smColumns={props.smColumns}>
      {props.data.map((item) => {
        return (
          <StyledInteractionDesignListItem key={item.number} section={props.section} color={item.color}>
            <video playsInline loop autoPlay muted src={item.src} ></video>
            <div className="container">
              {/* <p className="number">{item.number}</p> */}
              <h3 className="heading">{item.heading}</h3>
            </div>
          </StyledInteractionDesignListItem>
        )
      })}
    </StyledCardList>
  )
}

export { 
  CaseStudyList,
  CardList,
  PromptPlaygroundList,
  InteractionDesignList,
};
