const PortfolioItems = 
[
  {
    name: "Hangul Tools",
    // description: "A React app for Korean language learners to practice memorizing Korean numbers. Scripts generate problems for the user to solve to learn Hangul characters.",
    src: "/thumbnail/hangul-tools.png",
    links: [
      {
        href: "https://hangultools.com/",
        text: "View live site",
        target: "_blank",
        ariaLabel: "Go to Hangul Tools, opens in a new window",
      },
      {
        href: "https://github.com/jonathanhoy/hangultools",
        text: "View repository",
        target: "_blank",
        ariaLabel: "Go to Github, opens in a new window",
      }
    ],
  },
  {
    name: "Golf Life",
    // description: "A React app that displays statistics and data populated from Firebase. The Firebase database is connected to a Google Sheet allowing the site to be updated in real-time as changes are made on the Sheet.",
    src: "/thumbnail/golf-life.png",
    links: [
      {
        href: "https://golflife.netlify.app/",
        text: "View live site",
        target: "_blank",
        ariaLabel: "Go to Golf Life, opens in a new window",
      },
      {
        href: "https://github.com/jonathanhoy/golf-life",
        text: "View repository",
        target: "_blank",
        ariaLabel: "Go to Github, opens in a new window",
      }
    ],
  },
  {
    name: "Pokématch",
    // description: "Memory game built in React. Makes calls to an API to return random pokémon. Region and difficulty filters determine which series of and how many pokémon are returned. Leaderboard feature uses Firebase to keep track of the top scores for each region and difficulty.",
    src: "/thumbnail/pokematch.png",
    links: [
      {
        href: "https://jonathanhoy-pokematch.netlify.app/",
        text: "View live site",
        target: "_blank",
        ariaLabel: "Go to Pokematch, opens in a new window",
      },
      {
        href: "https://github.com/jonathanhoy/pokematch",
        text: "View repository",
        target: "_blank",
        ariaLabel: "Go to Github, opens in a new window",
      }
    ],
  },
  {
    name: "Connect Four",
    // description: "Built with JavaScript and utilizes jQuery to manipulate the DOM upon user interaction.",
    src: "/thumbnail/connect-four.png",
    links: [
      {
        href: "https://jonathanhoy.github.io/connect-four/",
        text: "View live site",
        target: "_blank",
        ariaLabel: "Go to Connect Four, opens in a new window",
      },
      {
        href: "https://github.com/jonathanhoy/jonathan_hoy_project3",
        text: "View repository",
        target: "_blank",
        ariaLabel: "Go to Github, opens in a new window",
      }
    ],
  },
]

export default PortfolioItems;