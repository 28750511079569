import styled from 'styled-components';
import { variables } from './GlobalStyles';

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1.25rem;
  li {
    background: ${props => props.itembackgroundcolor && `${props.itembackgroundcolor}`};
  }
`;

const TagItem = styled.li`
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  color: ${variables.grey900};
`;

export { TagList, TagItem };