import React from 'react';
import PortfolioItems from './data/portfolioitems';
import { Section, SectionHeading } from '../../styles/Section';
import Wrapper from '../../styles/Wrapper';
import { CardList } from '../list';

const Development = () => (
  <Section id="development" className="development">
    <Wrapper className="heading-wrapper">
      <SectionHeading>Development Projects</SectionHeading>
      <CardList data={PortfolioItems} section="development" className="development" columns="2" mdColumns="2" smColumns="1" />
    </Wrapper>
  </Section>
);

export default Development;