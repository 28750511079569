import styled from 'styled-components';
import { variables } from './GlobalStyles';

const StyledCardList = styled.ul`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  margin: 0;
  grid-template-columns: ${props => props.columns && `repeat(${props.columns}, 1fr)`};
  @media (max-width: ${variables.md}) {
    grid-template-columns: ${props => props.mdColumns && `repeat(${props.mdColumns}, 1fr)`};
  }
  @media (max-width: ${variables.sm}) {
    grid-template-columns: ${props => props.smColumns && `repeat(${props.smColumns}, 1fr)`};
 }
`;

const StyledCaseStudyList = styled.ul`
  display: grid;
  grid-gap: 4rem;
  margin: 0;
`;

const StyledCardListItem = styled.li`
  display: flex;
  flex-direction: column;
  .image {
    border-radius: ${variables.brstandard};
  }
  .container {
    flex-grow: 1;
    padding-top: 1.25rem;
    .heading {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .description {
      margin-top: 0.25rem;
    }
    .links {
      margin-top: 0.25rem;
    }
  }
  .heading {
    .arrow {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  &:hover {
    .heading {
      .arrow {
        opacity: 1;
      }
    }
  }
`;

const StyledCaseStudyListItem = styled(StyledCardListItem)`
  .img-container {
    border-radius: 1rem;
  }
  .container {
    .heading {
      font-size: 1.625rem;
    }
    .description {
      font-size: 1.125rem;
      font-weight: 400;
    }
  }
`;

const StyledPromptPlaygroundListItem = styled(StyledCardListItem)`
  .prompt {
    height: 100%;
    position: relative;
    .heading {
      display: inline-block;
      position: relative;
      margin-left: 1.5rem;
      background: ${variables.grey200};
      border-radius: 32px;
      padding: 0.25rem 0.75rem;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      border: 3px solid white;
      font-size: 0.875rem;
      font-family: 'SF Mono', monospace;
      text-transform: uppercase;
      font-weight: 500;
      color: ${variables.grey900};
    }
    img {
    }
    .prompt {
      line-height: 2.5;
      height: auto;
      margin-top: 1.25rem;
      font-weight: 400;
    }
    .prompt1 {
      text-transform: capitalize;
    }
    .prompt2, .prompt4 {
      background: ${variables.grey200};
      padding: 0.375rem 0.625rem;
      border-radius: 0.5rem;
      display: inline;
    }
    .responsive-block {
      @media (max-width: ${variables.md}) {
        display: block;
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        gap: 0.5rem;
        span {
          line-height: 1.5;
        }
        &:last-of-type {
          margin-top: 0.5rem;
        }
      }
    }
    a {
      margin-top: 1rem;
      transition: color 0.2s;
      color: ${variables.grey900};
      display: inline-block;
      span {
        transition: transform 0.2s;
        display: inline-block;
      }
      &:hover {
        color: ${variables.black};
        span {
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

const StyledInteractionDesignListItem = styled(StyledCardListItem)`
  video {
    max-width: 100%;
    border-radius: ${variables.brstandard};
  }
  position: relative;
  .container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
  }
  p, h3 {
    color: ${props => props.color};
  }
  p {
    font-family: 'SF Mono', monospace;
    font-weight: 400;
    line-height: 0.75;
  }
`;

export { 
  StyledCardList,
  StyledCardListItem,
  StyledCaseStudyList,
  StyledCaseStudyListItem,
  StyledPromptPlaygroundListItem,
  StyledInteractionDesignListItem,
};